<template>
    <main class="container m-auto max-w-full p-5 mt-2 z-0">
        <div class="font-bold text-2xl mb-10 text-center">Changelog</div>

        <div v-if="load" class="flex justify-center items-center gap-2">
            <span class="text-xl">Loading</span>
            <i class="fas fa-circle-notch fa-spin text-xl"></i>
        </div>

        <div v-if="error" class="text-red-800 dark:text-red-500 flex justify-center items-center font-bold text-xl">
            Changelog Server nicht erreichbar
        </div>

        <ChangelogEntry v-for="changelogEntry in changelog" :key="changelogEntry.id" class="mb-5"
            :id="changelogEntry.id"
            :version="changelogEntry.version"
            :date="changelogEntry.date"
            :description="changelogEntry.description" />
        
    </main>
</template>

<script>
import axios from 'axios';
import ChangelogEntry from '@/components/ChangelogEntry.vue';

export default {
    name: 'Changelog',
    components: {
        ChangelogEntry
    },
    data() {
        return {
            changelog: [],
            load: true,
            error: false,
        }
    },
    methods: {
        formatDate(date) {
            let dateObj = new Date(date);
            return dateObj.toLocaleDateString("de-DE");
        }
    },
    async created() {

        if ('changelogEntries' in sessionStorage) {

            this.changelog = JSON.parse(sessionStorage.getItem('changelogEntries'))

        } else {

            await axios.get('https://api.diafischchen.net/shinytracker/changelog')
                .then((response) => {
                    //console.log(response);
                    const changelog = response.data;
                    sessionStorage.setItem('changelogEntries', JSON.stringify(changelog))
                    this.changelog = changelog;
                })
                .catch(() => {
                    //console.log(error);
                    this.error = true;
                })

        }

        this.load = false;

    }
}
</script>

<style>

.container {
    width: 1200px;
}

.changelog-heading {
    @apply text-xl font-semibold;
}

.changelog-description {
    @apply mt-3;
}

.changelog-description ul {
    @apply my-2;
}

.changelog-description li {
    @apply list-disc ml-5;
}

</style>