<template>
    <div class="mt-5 bg-gray-200 dark:bg-gray-700 p-4 rounded-md shadow-md">
        <Accordion :header="`${formatDate(date)} - Version <span class='version'>${version}</span>`" :content="description" :id="id" :class="{ dark: darkTheme }" />
    </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'ChangelogEntry',
    components: {
        Accordion
    },
    props: [
        'id',
        'version',
        'date',
        'description'
    ],
    computed: {
        darkTheme() {
            return this.$store.state.darkTheme;
        }
    },
    methods: {
        formatDate(date) {
            let dateObj = new Date(date);
            return dateObj.toLocaleDateString("de-DE");
        }
    }
}
</script>

<style scoped>

::v-deep(.accordion-header) {
    @apply text-base md:text-lg select-none cursor-pointer font-bold text-gray-800 dark:text-gray-200;
}

::v-deep(.accordion-header .version) {
    @apply text-primary dark:text-dark-secondary;
}

::v-deep(.accordion-content) {
    @apply text-sm md:text-base;
}

::v-deep(.accordion-content.active) {
    @apply mt-4;
}

::v-deep(.accordion-content a) {
    @apply text-primary dark:text-dark-secondary underline;
}

::v-deep(.accordion-content ul) {
    @apply mb-3 mt-2 list-disc;
}

::v-deep(.accordion-content li) {
    @apply ml-5 md:ml-10;
}

</style>